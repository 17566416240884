import React, { useState, useEffect } from "react"
import Cookies from "universal-cookie"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AgegateUI from "../components/agegate/agegate-ui"
import Landing from "../components/landing/landing"
import 
Late from "../components/late/late"

const LandingPage = () => {
  // const [ageCheck, setAgeCheck] = useState(false)
  // useEffect(() => {
  //   let ageCookie = new Cookies()
  //   if (!ageCookie.get("isOldEnough")) {
  //     //navigate("/agegate")
  //     setAgeCheck(true)
  //     window.dataLayer = window.dataLayer || []
  //     window.dataLayer.push({
  //       event: "virtualPageview",
  //       page: "/agegate",
  //     })
  //   } else {
  //     setAgeCheck(false)
  //     window.dataLayer = window.dataLayer || []
  //     window.dataLayer.push({
  //       event: "virtualPageview",
  //       page: "/landingpage",
  //     })
  //   }
  // }, [])
  return (
    // <Layout>
    //   {ageCheck ? (
    //     <>
    //       <Seo title="Agegate | Chill or Thrill" />
    //       <AgegateUI setAgeCheck={setAgeCheck} />
    //     </>
    //   ) : (
    //     <>
    //       <Seo title="Home | Chill or Thrill" />
    //       {/* <Entry /> */}
    //       <Landing />
    //     </>
    //   )}
    // </Layout>

    <Layout>
      <>
        <Seo title="Promotion Closed | Chill or Thrill" />
        <Late />
      </>
    </Layout>
  )
}

export default LandingPage
