import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import winnersData from "../../winnersData/data.json";

const Late = () => (
  <>
    <div className="position-relative coming-soon">
      <div className="container-fluid position-absolute h-100 p-0">
        <div className="row g-0 h-100">
          <div className="col-6 block bg-theme-primary bg-chill position-relative">
            <div className="text-end heading">
              <h1 className="text-uppercase">Chill</h1>
            </div>
          </div>
          <div className="col-6 block bg-theme-dark bg-thrill position-relative">
            <div className="heading">
              <h1 className="text-uppercase">Thrill</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="cash-cards-block text-center">
        <div className="or mx-auto">
          <span className="text-uppercase">Or</span>
        </div>
        <div className="cash-cards mx-auto">
          <span className="text-uppercase">
            Plus <br />
            500 X $50 <br />
            Cash cards to <br />
            be Won
          </span>
        </div>
        <div className="chocolate-bars mx-auto">
          <StaticImage
            className="img-fluid"
            src="../../images/chocolate-bars.png"
            alt="chocolate bars"
          />
        </div>
      </div>
    </div>
    <section className="main-section pt-5">
      <div className="container">
        <div className="content text-center">
          <h2 className="text-theme-red text-uppercase">Promotion Closed!</h2>
          <p>
            Thanks for visiting the Mars Chill or Thrill Promotion site.
            <span className="d-md-block">
              Unfortunately, this Promotion ended at 11:59pm (AEST) on September
              12th, 2022.
            </span>
          </p>
          <p className="text-lg">
            Congratulations to our Chill winner N. Stoute, from WA, 6053.
          </p>
          <p className="text-lg pb-2">Congrats to all our instant winners.</p>
          <div className="row winner-details">
            {winnersData.map((winner) => (
              <div className="col-lg-3 col-md-4 col-6">
                <p>{winner["Final text"]}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Late;
